import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Loader } from "../components/loader";
import { Page } from "../components/skeleton";
import ServiceType, { GetServiceTypeByKey } from "../enums/serviceType";
import TimeOfDay from "../enums/timeOfDay";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { ApplicationConstants } from "../constants";

// Type definition for the form fields
interface FormFields {
  name: string;
  email: string;
  phone: string;
  date: string;
  timeOfDay: TimeOfDay;
  service: ServiceType;
  street: string;
  city: string;
  state: string;
  zip: string;
  message: string;
}

const BookNowPage: React.FC = () => {
  // Load ServiceType from Query String
  const { search } = useLocation();

  // State for form fields
  const [formFields, setFormFields] = useState<FormFields>({
    name: "",
    email: "",
    phone: "",
    date: "",
    timeOfDay: TimeOfDay.Any,
    service: ServiceType.DoubleSealWash,
    street: "",
    city: "",
    state: "",
    zip: "",
    message: "",
  });

  useEffect(() => {
    // Parse the query string
    const params = new URLSearchParams(search);
    const serviceQuery = params.get("service");
    if (serviceQuery) {
      const serviceType = GetServiceTypeByKey(serviceQuery);
      if (serviceType) {
        setFormFields((prev) => ({
          ...prev,
          service: ServiceType[serviceType],
        }));
      }
    }
  }, [search]);

  const classes = useStyles();

  // State for loading and error handling
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  // State for form validation
  const [isValid, setIsValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Handle form field changes with TypeScript typings
  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setFormFields((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    let formIsValid = true;

    if (!formFields.name.trim()) {
      formIsValid = false;
    }

    if (!formFields.email || !emailRegex.test(formFields.email)) {
      formIsValid = false;
    }

    if (!formFields.phone.trim() || formFields.phone.trim().length < 10) {
      formIsValid = false;
    }

    if (!formFields.date) {
      formIsValid = false;
    }

    if (!formFields.service.trim()) {
      formIsValid = false;
    }

    setIsValid(formIsValid);
    return formIsValid;
  };

  // Handle form submission
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission

    // Start Submission Process
    setIsSubmitting(true);

    // Validate Form
    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    // Prepare Data
    const requestData = {
      Name: formFields.name,
      Email: formFields.email,
      Phone: formFields.phone,
      Date: formFields.date,
      TimeOfDay: formFields.timeOfDay,
      Service: formFields.service,
      Location: `${formFields.street}, ${formFields.city}, ${formFields.state} ${formFields.zip}`,
      Message: formFields.message,
    };

    try {
      // Send Request
      const response = await fetch(
        "https://jamin-detail-booking-sender.azurewebsites.net/api/SendBookingRequest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      const result = await response.text();
      // Alert the result or success message
      alert(result);
      window.location.href = "/thank-you";
    } catch (error) {
      // Handle Submission Errors
      alert("There was an error submitting the form. Please try again.");
    } finally {
      // Reset IsSubmitting State
      setIsSubmitting(false);
    }
  };

  // Scroll to top of page on initial render
  useEffect(() => {
    document.title = `${ApplicationConstants.CompanyName} - Book Now`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="Book Now">
      {isLoading && <Loader position="top-center" type="inline" />}
      {!isLoading && (
        <main>
          <section>
            <h2>Book Now</h2>
            <form onSubmit={handleSubmit} className={classes.form}>
              <label htmlFor="name" className={classes.formLabel}>
                Name:<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formFields.name}
                onChange={handleChange}
                className={classes.formInput}
                required
              />
              <br />
              <br />

              <label htmlFor="email" className={classes.formLabel}>
                Email:<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formFields.email}
                onChange={handleChange}
                className={classes.formInput}
                required
              />
              <br />
              <br />

              <label htmlFor="phone" className={classes.formLabel}>
                Phone:
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formFields.phone}
                onChange={handleChange}
                className={classes.formInput}
              />
              <br />
              <br />

              <label htmlFor="date" className={classes.formLabel}>
                Date:<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="date"
                id="date"
                name="date"
                value={formFields.date}
                onChange={handleChange}
                className={classes.formInput}
                required
              />
              <br />
              <br />

              <label htmlFor="timeOfDay" className={classes.formLabel}>
                Time of Day:
              </label>
              <select
                name="timeOfDay"
                id="timeOfDay"
                value={formFields.timeOfDay}
                onChange={handleChange}
                className={classes.formInput}
              >
                {Object.values(TimeOfDay).map((time) => (
                  <option key={time} value={time}>
                    {time.charAt(0).toUpperCase() + time.slice(1)}
                  </option>
                ))}
              </select>
              <br />
              <br />

              <label htmlFor="service" className={classes.formLabel}>
                Service:<span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="service"
                id="service"
                value={formFields.service}
                onChange={handleChange}
                className={classes.formInput}
                required
              >
                {Object.entries(ServiceType).map(([key, service]) => (
                  <option key={key} value={service}>
                    {service}
                  </option>
                ))}
              </select>
              <br />
              <br />

              <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>Location:</legend>

                <label htmlFor="street" className={classes.formLabel}>
                  Street:
                </label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  value={formFields.street}
                  onChange={handleChange}
                  className={classes.formInput}
                  required
                />
                <br />
                <br />

                <label htmlFor="city" className={classes.formLabel}>
                  City:
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formFields.city}
                  onChange={handleChange}
                  className={classes.formInput}
                  required
                />
                <br />
                <br />

                <label htmlFor="state" className={classes.formLabel}>
                  State:
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formFields.state}
                  onChange={handleChange}
                  className={classes.formInput}
                  required
                />
                <br />
                <br />

                <label htmlFor="zip" className={classes.formLabel}>
                  Zip Code:
                </label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  value={formFields.zip}
                  onChange={handleChange}
                  className={classes.formInput}
                  required
                />
                <br />
                <br />
              </fieldset>

              <label htmlFor="message" className={classes.formLabel}>
                Message:
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                cols={50}
                value={formFields.message}
                onChange={handleChange}
                className={classes.formInput}
              ></textarea>
              <br />
              <br />

              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </section>
        </main>
      )}
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: 20,
    marginTop: 20,
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",

    backgroundColor: "#f4f4f4",

    ...(theme.palette.mode === "dark" && {
      backgroundColor: "#333333",
    }),

    ...(theme.palette.mode === "light" && {
      backgroundColor: "#f4f4f4",
    }),
  },

  formLabel: {
    display: "block",
    marginBottom: 5,
    color: "#333",
    fontWeight: "bold",
  },
  formInput: {
    width: "100%",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 4,
    boxSizing: "border-box",
  },
  formButton: {
    width: "auto",
    padding: "10px 30px",
    backgroundColor: "#007bff",
    color: "#ffffff",
    fontWeight: "bold",
    border: "none",
    borderRadius: 4,
    cursor: "pointer",
    display: "inline-block",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#0056b3",
    },
    "&:disabled": {
      backgroundColor: "#cccccc",
      color: "#666666",
      cursor: "not-allowed",
      opacity: 0.5,
    },
  },

  fieldset: {
    padding: "10px 20px",
    marginTop: 20,
    borderRadius: 5,
    '& input[type="text"]': {
      marginLeft: 20,
      marginBottom: 10,
      width: "calc(100% - 40px)",
    },
  },
  legend: {
    fontWeight: "bold",
    padding: "0 10px",
  },
  select: {
    width: "100%",
    padding: "8px 12px",
    border: "1px solid #ccc",
    borderRadius: 4,
    backgroundColor: "white",
    fontSize: 16,
    color: "#333",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    cursor: "pointer",
  },
}));

// body.dark-mode form {
//   background-color: #333333; /* Dark background for forms in dark mode */
// }

// body.dark-mode form label {
//   color: #e0e0e0; /* Lighter labels for readability in dark mode */
// }

// body.dark-mode form input[type="text"],
// body.dark-mode form input[type="email"],
// body.dark-mode form input[type="tel"],
// body.dark-mode form input[type="date"],
// body.dark-mode form textarea {
//   background-color: #454545; /* Darker input backgrounds for contrast */
//   border-color: #555; /* Darker borders for inputs */
//   color: #fff; /* Light text in inputs for readability */
// }

// body.dark-mode form button {
//   background-color: #0062cc; /* Adjusted button color for better visibility in dark mode */
// }

// .invalid-input {
//   border: 2px solid red;
// }

export default BookNowPage;
