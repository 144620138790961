import { useEffect, useState } from "react";
// Components
import makeStyles from "@mui/styles/makeStyles";
import { Loader } from "../components/loader";
import { Page } from "../components/skeleton";
import HeroBanner from "../components/heroBanner/HeroBanner";
import { Link, useLocation } from "react-router-dom";
import CTAButton from "../components/ctaButton/CTAButton";
import ServiceType, { GetServiceTypeByKey } from "../enums/serviceType";
import { ApplicationConstants } from "../constants";
import { Theme } from "@mui/material";

const PricingPage: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  interface ServiceData {
    serviceType: keyof typeof ServiceType;
    description?: string;
    features: string[];
    pricing: { vehicleType: string; price: string }[];
    imageUrl?: string;
    imageAlt?: string;
  }

  const servicesData: ServiceData[] = [
    {
      serviceType: "DoubleSealWash",
      description: "- 8 Month Protection",
      features: [
        "Clean Tires & Shine",
        "Foam Soak & Rinse",
        "Hand Wash & Rinse",
        "Clay Bar Wash",
        "Class Cleaning",
        "1 Step Machine Polishing",
        "Machine Applied 8 Month Polymer Sealant",
      ],
      pricing: [
        { vehicleType: "Compact", price: "$400" },
        { vehicleType: "Sedan", price: "$425" },
        { vehicleType: "Mid SUV/Truck", price: "$450" },
        { vehicleType: "Large SUV/Van", price: "$500" },
      ],
      imageUrl: "/images/pricing/double_seal_wash.webp",
      imageAlt: "Double Seal & Wash",
    },
    {
      serviceType: "ExteriorWashWax",
      features: [
        "Tires Cleaned & Shined",
        "Hand Wash",
        "Glass Cleaned",
        "Sio2 Spray Wax Applied",
      ],
      pricing: [
        { vehicleType: "Compact", price: "$140" },
        { vehicleType: "Sedan", price: "$160" },
        { vehicleType: "Mid SUV/Truck", price: "$190" },
        { vehicleType: "Large SUV/Van", price: "$225" },
        {
          vehicleType: "* Add Quick Interior Vacuum & Wipe Down",
          price: "$25-$100",
        },
      ],
      imageUrl: "/images/pricing/exterior_wash_wax.webp",
      imageAlt: "Exterior Wash & Wax",
    },
    {
      serviceType: "InteriorDetail",
      features: [
        "Seats, Floor, Trunk Vacuumed",
        "Leather Seats, Vinyl, Plastics Conditioned & UV Protected",
        "Glass Cleaned",
        "Floor Mats Cleaned",
      ],
      pricing: [
        { vehicleType: "Compact", price: "$100" },
        { vehicleType: "Sedan", price: "$130" },
        { vehicleType: "Mid SUV/Truck", price: "$160" },
        { vehicleType: "Large SUV/Van", price: "$225" },
      ],
      imageUrl: "/images/pricing/interior_detail.webp",
      imageAlt: "Interior Detail",
    },
    {
      serviceType: "QuickWash",
      features: ["Wash Whole Vehicle", "Clean & Shine Tires", "Glass Cleaned"],
      pricing: [
        { vehicleType: "Compact", price: "$75" },
        { vehicleType: "Sedan", price: "$100" },
        { vehicleType: "Mid SUV/Truck", price: "$125" },
        { vehicleType: "Large SUV/Van", price: "$150" },
      ],
      imageUrl: "/images/pricing/quick_wash.webp",
      imageAlt: "Quick Wash",
    },
    {
      serviceType: "VesselWashSeal",
      description: "FREE!!! Trailer Wash & Tire Shine",
      features: [
        "Foam Soak & Wash Whole Vessel",
        "Clean & UV Protect Seats",
        "Clean & Dress Trailer & Tires",
        "Clean Storage Compartments",
        "Metal Protection/Shine",
        "Glass Cleaning",
        "Gutters Cleaned",
        "1 Step DA Machine Polish - from water line to rub rail",
        "Machine Applied Polymer Sealant - from water line to rub rail",
      ],
      pricing: [{ vehicleType: "Up to 32'", price: "$30+ per foot" }],
      imageUrl: "/images/pricing/vessel_double_wash_seal.webp",
      imageAlt: "Vessel - Double Wash & Seal",
    },
    {
      serviceType: "VesselMaintenanceWash",
      features: [
        "Foam Soak & Wash Whole Vessel",
        "Clean Seats",
        "Sio2 Spray Applied",
        "Glass Cleaning",
        "Gutters Cleaned",
      ],
      pricing: [{ vehicleType: "Up to 32'", price: "$20+ per foot" }],
      imageUrl: "/images/generated/boat_red.webp",
      imageAlt: "Vessel - Maintenance Wash",
    },
    {
      serviceType: "VesselQuickWash",
      features: ["Wash Whole Vessel", "Vacuum if needed"],
      pricing: [{ vehicleType: "Up to 32'", price: "$10+ per foot" }],
      imageUrl: "/images/generated/boat.webp",
      imageAlt: "Vessel - Quick Wash",
    },
    {
      serviceType: "JetSkiQuickWash",
      features: ["Wash Whole Jet Ski"],
      pricing: [{ vehicleType: "All Sizes", price: "$50+" }],
      imageUrl: "/images/generated/jet_ski_soap.webp",
      imageAlt: "Jet Ski",
    },
    {
      serviceType: "MotorcycleQuickWash",
      features: ["Wash Whole Motorcycle"],
      pricing: [{ vehicleType: "All Sizes", price: "$75+" }],
      imageUrl: "/images/generated/motorcycle_wheel.webp",
      imageAlt: "Motorcycle",
    },
  ];

  // Load ServiceType from Query String
  const { search } = useLocation();

  useEffect(() => {
    // Parse the query string
    const params = new URLSearchParams(search);
    const serviceQuery = params.get("service");
    if (serviceQuery) {
      const serviceType = GetServiceTypeByKey(serviceQuery);
      if (serviceType) {
        console.log(`Scrolling to service: ${serviceType}`);
        // Scroll to the service section
        const serviceElement = document.getElementById(serviceType);
        if (serviceElement) {
          setTimeout(() => {
            serviceElement.scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      }
    }
  }, [search]);

  useEffect(() => {
    document.title = `${ApplicationConstants.CompanyName} - Pricing`;
    // window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="Pricing">
      {isLoading && <Loader position="top-center" type="inline" />}
      {!isLoading && (
        <main>
          <HeroBanner />

          <section id="services">
            <h2 className={classes.sectionTitle}>
              Our Premium Mobile Detailing Services
            </h2>

            {servicesData.map((service) => (
              <div className={classes.serviceRow} key={service.serviceType}>
                <div className={classes.serviceText}>
                  <h3>{ServiceType[service.serviceType]}</h3>
                  <h5>{service.description}</h5>
                  <ul>
                    {service.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <hr />
                  <table className={classes.pricingTable}>
                    <tbody>
                      {service.pricing.map((priceDetail, index) => (
                        <tr key={index}>
                          <td>{priceDetail.vehicleType}</td>
                          <td>{priceDetail.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <CTAButton serviceType={service.serviceType} />
                </div>
                <div className={classes.serviceImage}>
                  <img src={service.imageUrl} alt={service.imageAlt} />
                </div>
              </div>
            ))}
          </section>
        </main>
      )}
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  serviceRow: {
    maxWidth: "2200px",
    display: "flex",
    alignItems: "stretch",
    backgroundColor: "#004a99",
    color: "#fff",
    marginBottom: "30px",
    borderRadius: "8px",
    overflow: "hidden",

    /* This rule will switch the order for the text block in every even row */
    "&:nth-child(even)": {
      flexDirection: "row-reverse",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
      // Removes the order change on smaller screens for a consistent top-to-bottom flow
      "&:nth-child(even)": {
        flexDirection: "column",
      },
    },
  },
  serviceText: {
    flexBasis: "50%",
    padding: "25px" /* Keeps padding for the text container */,

    "& h3": {
      textAlign: "center",
      fontSize: "1.8rem",
      color: "#fff",
      // marginBottom: "15px",
      textDecoration: "underline",
      paddingBottom: "0px",
    },
    "& p": {
      fontSize: "1rem",
      lineHeight: "1.6",
    },

    "& h5": {
      textAlign: "center",
    },
    "& ul": {
      listStyleType: "none" /* Removes default list styling */,
      padding: "0",
      margin: "20px 0",
    },
    "& ul li": {
      marginBottom: "10px" /* Adds spacing between list items */,
      position: "relative" /* For pseudo-element positioning */,
    },
    "& ul li:before": {
      content: '"•"',
      position: "absolute",
      left: "-10px",
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      padding: "15px 0",
    },
  },
  serviceCtaButton: {
    display: "block",
    width: "max-content",
    margin: "20px auto",
    padding: "10px 30px",
    fontSize: "1.1rem",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#003366",
    },
  },
  buttonContainer: {
    textAlign: "center", // This will center the inline-block elements inside it */,
    marginBottom: "10px", // Optional: Adds space below the container ,
  },
  viewPricingBtn: {
    display: "inline-block",
    padding: "8px 20px",
    color: "#007bff",
    backgroundColor: "transparent",
    borderRadius: "5px",
    textDecoration: "none",
    fontWeight: "normal",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      color: "#ffffff",
    },
  },

  serviceImage: {
    flexBasis: "50%",
    padding: "0px",
    display: "flex",
    alignItems: "stretch",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },

  pricingTable: {
    width: "100%" /* Makes the table use the full width of its container */,
    marginTop: "20px" /* Adds some space above the table */,
    borderCollapse: "collapse" /* Removes the space between table borders */,
    "& td": {
      padding: "5px 15px" /* Adds padding inside each table cell for spacing */,
    },
    "& tr:last-child td": {
      borderBottom:
        "none" /* Removes the bottom border from the last row's cells */,
    },
  },

  ctaButton: {
    padding: "10px 30px",
    fontSize: "1.1rem",
    color: "#fff",
    backgroundColor: "#004a99",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#003366",
    },
  },
  jaminDetail: {
    color: "#004a99",
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: "2.2rem",
    marginBottom: "40px",
    color: "#004a99", // Feel free to adjust the color to match your branding
  },
}));

export default PricingPage;
