enum ServiceType {
  DoubleSealWash = "Double Seal & Wash",
  ExteriorWashWax = "Exterior Wash & Wax",
  InteriorDetail = "Interior Detail",
  QuickWash = "Quick Wash",
  VesselWashSeal = "Vessel Wash & Seal",
  VesselMaintenanceWash = "Vessel - Maintenance Wash",
  VesselQuickWash = "Vessel - Quick Wash",
  JetSkiQuickWash = "Jet Ski - Quick Wash",
  MotorcycleQuickWash = "Motorcycle - Quick Wash",
}

// Function to map the query string to the enum key
export const GetServiceTypeByKey = (
  key: string
): keyof typeof ServiceType | undefined => {
  if (Object.keys(ServiceType).includes(key)) {
    return key as keyof typeof ServiceType;
  }
  return undefined;
};

export const ServiceTypeImages: Record<keyof typeof ServiceType, string> = {
  DoubleSealWash: "/images/index/double_seal_wash.webp",
  ExteriorWashWax: "/images/index/exterior_wash_wax.webp",
  InteriorDetail: "/images/index/interior_detailing.webp",
  QuickWash: "/images/index/quick_wash.webp",
  VesselWashSeal: "/images/index/vessel_wash_seal.webp",
  VesselMaintenanceWash: "/images/generated/boat_blue.webp",
  VesselQuickWash: "/images/generated/boat_blue_side.webp",
  JetSkiQuickWash: "/images/generated/jet_ski.webp",
  MotorcycleQuickWash: "/images/generated/motorcycle.webp",
};

export const ServiceTypeDescriptions: Record<keyof typeof ServiceType, string> =
  {
    DoubleSealWash: `Discover enhanced care for your vehicle with our Double Seal &
  Wash package, designed to provide thorough cleaning and
  protection. This service starts with a detailed cleaning of your
  tires, followed by a foam soak to gently lift dirt and debris from
  your vehicle's exterior. A careful hand wash and rinse come next,
  ensuring a clean surface, which is then refined with a clay bar
  treatment for a smooth finish. Windows are cleaned for clear
  visibility, and the vehicle's shine is revitalized with a 1-step
  machine polishing. The process concludes with the application of
  an 8-month polymer sealant by machine, offering your vehicle a
  layer of protection and a refreshed look.`,
    ExteriorWashWax: `Give your vehicle's exterior the care it deserves with our
  Exterior Wash & Wax service. We start by thoroughly cleaning and
  shining your tires, ensuring they look neat and tidy. Next, our
  team carefully hand washes your vehicle, tackling dirt and road
  grime to bring back its clean appearance. We don't overlook the
  windows either, cleaning them thoroughly for clarity and improved
  driving safety. The finishing touch is our SiO2 spray wax
  application, providing your vehicle with a subtle shine and a
  protective layer to help guard against the elements. It's a
  simple, effective way to maintain your vehicle's look and
  longevity.`,
    InteriorDetail: `Keep the inside of your vehicle looking and feeling fresh with our
  Interior Detail service. We start by thoroughly vacuuming the
  seats, floor, and trunk, removing dust and debris to create a
  cleaner, more inviting environment. For vehicles with leather
  seats, as well as other vinyl and plastic surfaces, we provide a
  careful conditioning treatment that not only rejuvenates their
  appearance but also offers UV protection to help prevent fading
  and cracking over time. Windows and glass surfaces are
  meticulously cleaned for streak-free clarity, enhancing your
  driving view. We also give special attention to cleaning the floor
  mats, ensuring every part of your vehicle's interior is attended
  to. This service is all about maintaining a clean, comfortable,
  and well-protected interior space for you and your passengers.`,
    QuickWash: `Our Quick Wash service is designed for those who value both time
  and cleanliness. It covers a complete wash of the entire vehicle,
  ensuring that every surface is attended to with care. We also
  focus on the tires, cleaning and shining them to enhance the
  overall look of your vehicle. To top it off, we clean the glass
  surfaces, removing smudges and streaks to provide you with a clear
  view of the road. This service is great for maintaining your
  vehicle's appearance without taking up too much of your time.`,
    VesselWashSeal: `The Vessel Wash & Seal service offers a targeted approach to
  maintaining your boat's appearance and durability. We begin with a
  detailed foam soak and hand wash, covering the entire vessel for a
  deep clean. Seats are cleaned and treated with UV protection to
  preserve their condition, while the trailer and tires are dressed
  to complement the boat's cleanliness. Essential storage
  compartments are also tidied up. We apply a metal protection shine
  to keep metal surfaces gleaming and protected. Glass cleaning
  ensures clear views, and gutter clearing maintains proper
  drainage. The service is completed with a DA machine polish and a
  polymer sealant application from the waterline to the rub rail,
  providing a lasting shine and protective layer.`,
    VesselMaintenanceWash: `Maintain the pristine condition of your boat with our Vessel -
  Maintenance Wash. This streamlined service begins with a
  comprehensive foam soak and wash, ensuring every part of the
  vessel is meticulously cleaned. The cleaning extends to the seats,
  where we remove everyday dirt and refresh the fabric or leather. A
  protective SiO2 spray is then applied, adding a layer of shine and
  safeguarding the surface against the elements. Windows and glass
  are polished to clarity, enhancing your view of the open waters.
  We also take care of the gutters, clearing them out to prevent
  blockages and maintain proper drainage. This service is ideal for
  regular upkeep, keeping your vessel looking its best with minimal
  fuss.`,
    VesselQuickWash: `Keep your vessel in tip-top shape with our Vessel - Quick Wash
  service. Designed for efficiency and effectiveness, this service
  provides a thorough wash of the entire vessel, tackling salt,
  grime, and everyday buildup to restore its shine. Additionally, we
  offer vacuuming services as needed to ensure the interior matches
  the exterior's refreshed state. Ideal for those in need of a swift
  and reliable cleaning solution, the Vessel - Quick Wash ensures
  your boat is ready for its next voyage in no time.`,
    JetSkiQuickWash: `Revitalize your jet ski with our Jet Ski - Quick Wash service.
  This straightforward offering ensures a comprehensive wash of your
  jet ski, removing salt, sand, and other residues that accumulate
  during your water adventures. Designed to quickly refresh and
  maintain the appearance of your jet ski, this service is perfect
  for those looking for a fast and effective cleaning solution,
  keeping your jet ski in great condition and ready for the next
  ride.`,
    MotorcycleQuickWash: `Energize your ride with our Motorcycle - Quick Wash service.
  Tailored for the two-wheeled enthusiast, this service focuses on
  thoroughly washing your motorcycle, cutting through road grime,
  dust, and dirt to restore its luster. Ideal for those seeking a
  quick yet effective cleaning solution, our Quick Wash ensures your
  motorcycle not only looks its best but is also primed for your
  next journey.`,
  };

export default ServiceType;
